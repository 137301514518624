import React, { useState, useEffect, useCallback } from 'react';
import { Form, Input, DatePicker, Select, Button, notification, Drawer } from 'antd';
import axios from 'axios';
import moment from 'moment';

import { SERVER_URL } from '../../config';

const { TextArea } = Input;
const { Option } = Select;

let user = JSON.parse(sessionStorage.getItem('user'));
const headers = {
  withCredentials: false,
  headers: { Authorization: `Bearer ${user?.token}` },
};

const OnlineStudyModdal = ({ showModal, getStudies, studyData, closeStudy }) => {
  const [form] = Form.useForm();
  const [categories, setCategories] = useState([]);
  const [adminUsers, setAdminUsers] = useState([]);
  const [participants, setParticipants] = useState();

  const getSameDescriptions = useCallback(async () => {
    try {
      const { data } = await axios.get(`${SERVER_URL}/description?id=${studyData.data._id}`, headers);
      setParticipants(data);
    } catch (error) {
      console.log(error.message);
    }
  }, [studyData]);

  const getAdminUsers = async () => {
    try {
      const { data } = await axios.get(`${SERVER_URL}/users/admin`, headers);
      setAdminUsers(data);
    } catch (error) {
      if (error.response?.data.message) {
        notification.error({
          message: error.response.data.message,
          placement: 'bottomRight',
          duration: 2.5,
        });
      }
      console.log(error.message);
    }
  };

  useEffect(() => {
    getAdminUsers();
  }, []);

  useEffect(() => {
    studyData.data && getSameDescriptions();
  }, [getSameDescriptions, studyData]);

  useEffect(() => {
    if (studyData.data)
      form.setFieldsValue({
        ...studyData.data,
        start: moment(studyData.data.start),
        end: moment(studyData.data.end),
      });
  }, [form, studyData]);

  const getCategories = async () => {
    try {
      const { data } = await axios.get(`${SERVER_URL}/categories`, headers);
      setCategories(data);
    } catch (error) {
      if (error.response?.data.message) {
        notification.error({
          message: error.response.data.message,
          placement: 'bottomRight',
          duration: 2.5,
        });
      }
      console.log(error.message);
    }
  };

  useEffect(() => {
    getCategories();
  }, []);

  const disabledDate = (moment) => {
    const date = moment.toDate();
    const now = new Date();
    if (date > now) return false;
    return true;
  };

  const create = async (values) => {
    try {
      const method = !studyData.data ? 'post' : 'put';
      const url = !studyData.data
        ? `${SERVER_URL}/online-studies`
        : `${SERVER_URL}/online-studies/${studyData.data._id}`;

      const { data } = await axios[method](url, values, headers);
      notification.success({
        message: data.message,
        placement: 'bottomRight',
        duration: 2.5,
      });
      closeModal();
      getStudies();
    } catch (error) {
      if (error.response.data.message) {
        notification.error({
          message: error.response.data.message,
          placement: 'bottomRight',
          duration: 2.5,
        });
      }
      console.log(error.message);
    }
  };

  const closeModal = () => {
    closeStudy();
    form.resetFields();
  };

  return (
    <Drawer
      title={<strong>{!studyData.data ? 'Studie hinzufügen' : 'Studie aktualisieren'}</strong>}
      open={showModal}
      onClose={closeModal}
      placement='right'
      width={420}
    >
      <Form onFinish={create} form={form} disabled={studyData.mode === 'VIEW' ? true : false}>
        <Form.Item
          name='accountForTimeSpent'
          label='Anrechnung des Zeitaufwands'
          rules={[{ required: true, message: 'Pflichtfeld!' }]}
        >
          <Select>
            <Option value='Automatisierte Anrechnung (Online-Umfrage mit Redirect)'>
              Automatisierte Anrechnung (Online-Umfrage mit Redirect)
            </Option>
            <Option value='Eingabe eines Codes (Generierung einer Code-Liste)'>
              Eingabe eines Codes (Generierung einer Code-Liste)
            </Option>
            <Option value='Hochladen einer Teilnahmebestätigung (Ausstellung einer Bestätigung notwendig)'>
              Hochladen einer Teilnahmebestätigung (Ausstellung einer Bestätigung notwendig)
            </Option>
          </Select>
        </Form.Item>
        <Form.Item name='description' label='Bezeichnung' rules={[{ required: true, message: 'Pflichtfeld!' }]}>
          <Input type='text' placeholder='Titel der Studie' />
        </Form.Item>
        <Form.Item name='briefDescription' label='Beschreibung' rules={[{ required: true, message: 'Pflichtfeld!' }]}>
          <TextArea rows={4} />
        </Form.Item>
        <Form.Item label='Verfügbarkeitszeitraum'>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <Form.Item name='start' rules={[{ required: true, message: 'Pflichtfeld!' }]}>
              <DatePicker showToday={false} disabledDate={disabledDate} format={'DD/MM/YYYY'} />
            </Form.Item>
            <Form.Item name='end' rules={[{ required: true, message: 'Pflichtfeld!' }]}>
              <DatePicker showToday={false} disabledDate={disabledDate} format={'DD/MM/YYYY'} />
            </Form.Item>
          </div>
        </Form.Item>
        <Form.Item
          name='participationDuration'
          label='Teilnahmedauer'
          rules={[{ required: true, message: 'Pflichtfeld!' }]}
        >
          <Input type='number' placeholder='Dauer in Minuten' />
        </Form.Item>
        <Form.Item name='category' label='Disziplin' rules={[{ required: true, message: 'Pflichtfeld!' }]}>
          <Select
            mode='multiple'
            showSearch
            showArrow
            filterOption={(value, compare) => compare.children.toLowerCase().includes(value.toLowerCase())}
          >
            {categories?.map((category) => (
              <Option key={category._id} value={category._id}>
                {category.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item name='link' label='Link' rules={[{ required: true, message: 'Pflichtfeld!' }]}>
          <Input type='text' placeholder='Link zur Studie' />
        </Form.Item>
        <Form.Item
          name='panel'
          label='Teilnehmer-Panel'
          rules={!studyData.data && [{ required: true, message: 'Pflichtfeld!' }]}
        >
          <Select>
            <Option value='Kein Panel ausgewählt'>Kein Panel ausgewählt</Option>
            <Option value='Test-Panel'>Test-Panel</Option>
          </Select>
        </Form.Item>
        <Form.Item name='institution' label='Institution' rules={[{ required: true, message: 'Pflichtfeld!' }]}>
          <Input type='text' placeholder='Institution' />
        </Form.Item>
        <Form.Item name='contact' label='Ansprechperson' rules={[{ required: true, message: 'Pflichtfeld!' }]}>
          <Input type='text' placeholder='Ansprechperson' />
        </Form.Item>
        <Form.Item name='email' label='E-Mail-Adresse' rules={[{ required: true, message: 'Pflichtfeld!' }]}>
          <Input type='email' placeholder='EMail-Adresse' />
        </Form.Item>
        <Form.Item
          name='supervisor'
          label='Betreuer'
          rules={!studyData.data && [{ required: true, message: 'Pflichtfeld!' }]}
        >
          <Select
            showSearch
            showArrow
            filterOption={(value, compare) => compare.children.join().toLowerCase().includes(value.toLowerCase())}
          >
            {adminUsers.map((admin) => (
              <Option key={admin._id} value={admin._id}>
                {admin.firstName} {admin.lastName}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <div className='participants'>
          <div className='participants__list'>
            {participants?.length ? (
              participants.map((p) => (
                <p key={p.userId} className='participants__entry'>
                  <span className='participants__entry-student'>
                    {p.firstName} {p.lastName} ({p.code})
                  </span>
                  <span className='participants__entry-status'>
                    {p.duration >= p.studyDuration ? 'Vollendet' : 'Nicht vollständig'}
                  </span>
                </p>
              ))
            ) : (
              <p className='participants__entry participants__entry--empty'>Keine Teilnehmer</p>
            )}
          </div>
        </div>
        {studyData.mode !== 'VIEW' && (
          <div style={{ display: 'flex', gap: '1rem', justifyContent: 'flex-end', marginTop: '1.5rem' }}>
            <Button type='primary' htmlType='submit' style={{ marginLeft: '15px' }}>
              {!studyData.data ? 'Speichern und Weiter' : 'Aktualisieren'}
            </Button>
            <Button type='default' onClick={closeModal}>
              Schließen
            </Button>
          </div>
        )}
      </Form>
    </Drawer>
  );
};

export default OnlineStudyModdal;
