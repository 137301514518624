import React, { useEffect, useContext, useState } from 'react';
import Axios from 'axios';
import { SearchOutlined } from '@ant-design/icons';
import { notification, Button, Input } from 'antd';
import { UserContext } from '../../App';
import useAxios from '../../hooks/useAxios';
import { Table } from '../../components/tables';
import { SERVER_URL } from '../../config';
import EditUserModal from '../../components/modals/EditModal';
import EditSemester from '../../pages/users/EditSemester';
import axios from 'axios';

const user = JSON.parse(sessionStorage.getItem('user'));
const headers = {
  withCredentials: false,
  headers: { Authorization: `Bearer ${user?.token}` },
};

const Users = () => {
  const currentuser = useContext(UserContext);
  const [users, setUsers] = useState([]);
  const [semesters, fetchSemesters] = useAxios('', null, currentuser.data.token, 'get');
  const [showModal, setShowModal] = useState();
  const [showSemesterModal, setShowSemesterModal] = useState();
  const [search, setSearch] = useState();
  const [useri, setUseri] = useState();
  const [isAddUserActive, setIsAddUserActive] = useState(false);
  const [csvUsers, setCsvUsers] = useState(undefined);

  const getUsers = async () => {
    try {
      const { data } = await axios.get(`${SERVER_URL}/users`, headers);
      setUsers(data);
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    getUsers();
  }, []);

  useEffect(() => {
    fetchSemesters(`${SERVER_URL}/semesters`, []);
  }, [semesters, fetchSemesters]);

  useEffect(() => {
    let newUsersData = [];
    if (users.items) {
      for (const user of users.items) {
        newUsersData.push(user);
      }
    }
    setCsvUsers(newUsersData);
  }, [users]);

  useEffect(() => {
    const body = document.querySelector('body');
    body.style.overflow = showModal ? 'hidden' : 'auto';
  }, [showModal]);

  //Moze da se pretrazuje po imenu, prezimenu, role, status, grad,email
  //Nalazi se odredjivanje toga u user.controllers.js u searchUsers

  useEffect(() => {
    if (search) {
      Axios.get(`${SERVER_URL}/users/search/${search}`)
        .then((res) => setUseri(res.data.users))
        .catch((err) => console.log(err));
    } else {
      setUseri([]);
    }
  }, [search, currentuser]);

  const deleteUserHandler = async (id) => {
    try {
      await Axios.delete(`${SERVER_URL}/users/${id}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });
      notification.success({
        message: 'User is deleted.',
        placement: 'bottomRight',
      });
      getUsers();
    } catch (err) {
      notification.error({
        message: 'Problem with delete. Please try later.',
        placement: 'bottomRight',
      });
    }
  };

  const changeStatus = async (id, status) => {
    try {
      await axios.put(`${SERVER_URL}/users/${id}`, { status }, headers);
      notification.success({
        message: 'Zustand aktualisiert',
        placement: 'bottomRight',
      });
      getUsers();
    } catch (error) {
      console.log(error.message);
    }
  };

  const changeRole = async (id, role) => {
    try {
      await axios.put(`${SERVER_URL}/users/${id}`, { role }, headers);
      notification.success({
        message: 'Zustand aktualisiert',
        placement: 'bottomRight',
      });
      getUsers();
    } catch (error) {
      console.log(error.message);
    }
  };

  const columnKeys = ['Matr.Nr./VK-Nr.', 'Name', 'email', 'VP(Min.)'];

  let tableData = [];
  if (search) {
    if (useri && useri.length > 0) {
      tableData = useri.map((item) => {
        item['VP(Min.)'] = item.studies?.reduce((a, b) => a + b.participationDuration, 0) || '';
        item['Matr.Nr./VK-Nr.'] = item.code;
        item['Name'] = `${item.firstName} ${item.lastName}`;
        item.createdAt = new Date(item.createdAt).toLocaleString();
        item.updatedAt = new Date(item.updatedAt).toLocaleString();
        return { ...item };
      });
    }
  } else if (users.items && users.items.length > 0) {
    tableData = users.items.map((item) => {
      item['VP(Min.)'] = item.studies?.reduce((a, b) => a + b.participationDuration, 0) || '';
      item['Matr.Nr./VK-Nr.'] = item.code;
      item['Name'] = `${item.firstName} ${item.lastName}`;
      item.createdAt = new Date(item.createdAt).toLocaleString();
      item.updatedAt = new Date(item.updatedAt).toLocaleString();
      return { ...item };
    });
  }

  const addUserHandler = () => {
    setShowModal(true);
    setIsAddUserActive(true);
  };

  return (
    <div className='table usersWrapper'>
      <div className='actions-block' style={{ marginBottom: '8px' }}>
        <Input
          bordered={false}
          placeholder='Suchen'
          prefix={<SearchOutlined />}
          onChange={(e) => setSearch(e.target.value)}
        />
        <div className='desna-strana'>
          <Button onClick={() => setShowSemesterModal(!showSemesterModal)} type='secondary'>
            Semester
          </Button>
          <Button onClick={addUserHandler} type='primary'>
            Benutzer hinzufügen
          </Button>
        </div>
      </div>

      <div style={{ textAlign: 'center' }}>
        {users.items && users.items.length > 0 && (
          <Table
            data={tableData}
            deleteHandler={deleteUserHandler}
            columnKeys={columnKeys}
            title='User'
            changeStatus={changeStatus}
            changeRole={changeRole}
            getUsers={getUsers}
          />
        )}
        {users.items && users.items.length === 0 && (
          <div className='no-data-box'>
            <h2>No Data</h2>
          </div>
        )}
      </div>
      <EditUserModal
        getUsers={getUsers}
        showModal={showModal}
        setShowModal={setShowModal}
        semesters={semesters}
        isAddUserActive={isAddUserActive}
      />
      <EditSemester
        showSemesterModal={showSemesterModal}
        setShowSemesterModal={setShowSemesterModal}
        semesters={semesters}
      />
    </div>
  );
};

export default Users;
