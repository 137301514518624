import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { SERVER_URL } from '../../config';
import StudyTable from '../../components/tables/StudyTable';
import { Card, Drawer, Input, Button, notification, Modal } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { UserContext } from '../../App';

let user = JSON.parse(sessionStorage.getItem('user'));
const headers = {
  withCredentials: false,
  headers: { Authorization: `Bearer ${user?.token}` },
};

const Studies = () => {
  const [openLog, showLogs] = useState(false);
  const [logs, setLogs] = useState([]);
  const [studies, setStudies] = useState([]);
  const [modalData, setModalData] = useState(undefined);
  const [comment, setComment] = useState('');
  const [rejectedReason, setRejectedReason] = useState('');
  const [rejectedModal, setRejectedModal] = useState(false);
  const [search, setSearch] = useState();
  const currentuser = useContext(UserContext);

  const getLogs = async () => {
    try {
      const { data } = await axios.get(`${SERVER_URL}/status-log`, headers);
      setLogs(data);
    } catch (err) {
      if (err.response?.data.message) {
        notification.warn({
          message: err.response.data.message,
          placement: 'bottomRight',
          duration: 2.5,
        });
      }
      console.log(err.message);
    }
  };

  useEffect(() => {
    getLogs();
  }, []);

  const getStudies = async () => {
    try {
      const { data } = await axios.get(`${SERVER_URL}/studies`, headers);
      setStudies(data);
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    getStudies();
  }, [search]);

  const columnKeys = ['Antrags ID', 'Bezeichnung', 'Name', 'VP (Min).', 'Matnr', 'Eingereicht am'];

  const tableData = studies?.map((study) => ({
    ...study,
    'Antrags ID': study.id,
    Bezeichnung: study.designation,
    Name: `${study.submittedBy.firstName} ${study.submittedBy.lastName}`,
    'VP (Min).': study.participationDuration,
    Matnr: study.submittedBy.code,
    'Eingereicht am': study.performedOn,
    Status: study.status,
  }));

  const saveComment = async () => {
    try {
      const { data } = await axios.put(`${SERVER_URL}/studies/${modalData._id}`, { internalComment: comment }, headers);
      notification.success({
        message: data.message,
        placement: 'bottomRight',
        duration: 2.5,
      });
      getStudies();
    } catch (error) {
      console.log(error.message);
    }
  };

  const changeStatus = async (status) => {
    try {
      const { data } = await axios.put(
        `${SERVER_URL}/studies/${modalData._id}`,
        { status, rejectedReason, antragId: modalData.id, userId: user.id },
        headers,
      );
      notification.success({
        message: data.message,
        placement: 'bottomRight',
        duration: 2.5,
      });
      setModalData(undefined);
      getStudies();
      getLogs();
    } catch (error) {
      console.log(error.message);
    }
  };

  const deleteStudyHandler = async (id) => {
    try {
      await axios.delete(`${SERVER_URL}/studies/${id}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });
      notification.success({
        message: 'Item is deleted.',
        placement: 'bottomRight',
      });
      setTimeout(() => {
        window.location.reload();
      }, 500);
    } catch (err) {
      notification.error({
        message: 'Problem with delete. Please try later.',
        placement: 'bottomRight',
      });
    }
  };

  return (
    <div className='table usersWrapper'>
      <div className='actions-block' style={{ marginBottom: '8px' }}>
        <Input
          bordered={false}
          placeholder='Suchen'
          prefix={<SearchOutlined />}
          onChange={(e) => setSearch(e.target.value)}
        />
        <button style={{ zIndex: '10' }} className='ant-btn ant-btn-primary' onClick={() => showLogs(true)}>
          Statusänderungsprotokoll
        </button>
      </div>
      {studies?.length > 0 && (
        <StudyTable
          data={tableData}
          columnKeys={columnKeys}
          setModalData={setModalData}
          setComment={setComment}
          getStudies={getStudies}
          deleteStudyHandler={deleteStudyHandler}
        />
      )}
      {studies?.length === 0 && (
        <div className='no-data-box'>
          <h2 style={{ textAlign: 'center' }}>No Studies</h2>
        </div>
      )}
      <Drawer
        style={{ padding: '0px' }}
        visible={Boolean(modalData)}
        onClose={() => setModalData(undefined)}
        title={<strong>VPH-Antrag ({modalData?.id})</strong>}
      >
        <Card bordered={false} style={{ width: '100%', padding: 0, boxShadow: 'none' }}>
          <p>
            Eingereicht von:{' '}
            <strong>
              {modalData?.Name} (ID: {modalData?.submittedBy.id})
            </strong>
          </p>
          <p>
            Eingereicht am: <strong>{modalData?.performedOn}</strong>
          </p>
          <p>
            Institution: <strong>{modalData?.institution}</strong>
          </p>
          <p>
            Beschreibung: <strong>{modalData?.description}</strong>
          </p>
          <p>
            Themenbereiche: <strong>{modalData?.subjectAreas?.name}</strong>
          </p>
          <p>
            Durchgeführt am: <strong>{modalData?.filedOn}</strong>
          </p>
          <p>
            Teilnahmedauer in Minuten: <strong>{modalData?.participationDuration}</strong>
          </p>
          <p>
            Bestätigung:{' '}
            <a href={modalData?.confirmationProof?.url} target='_blank' rel='noreferrer'>
              {modalData?.confirmationProof?.url}
            </a>
          </p>
          <p>
            Interne Notiz:
            <Input.TextArea
              key={modalData?._id}
              defaultValue={comment}
              rows={3}
              onChange={(e) => setComment(e.target.value)}
            />
            <Button style={{ float: 'right', marginTop: '10px' }} onClick={saveComment}>
              Absenden
            </Button>
          </p>
          <br />
          <h2 style={{ fontWeight: 'bold' }}>Fragen nach Studie</h2>
          <p>
            Was war(en) Ihrer Meinung nach die Fragestellung(en) der Studie?
            <Input type='text' value={modalData?.questionOneAnswer} readOnly />
          </p>
          <p>
            Welche methodischen Stärken sind Ihnen aufgefallen?
            <Input type='text' value={modalData?.questionTwoAnswer} readOnly />
          </p>
          <p>
            Welche methodischen Schwächen sind Ihnen aufgefallen?
            <Input type='text' value={modalData?.questionThreeAnswer} readOnly />
          </p>
          <p>
            Welche sonstigen Anmerkungen haben Sie zur Studie?
            <Input type='text' value={modalData?.questionFourAnswer} readOnly />
          </p>
          <div
            style={{
              marginTop: '1.8rem',
              float: 'right',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexWrap: 'wrap',
              gap: '10px',
            }}
          >
            <Button style={{ order: 3, width: '70%' }} onClick={() => setModalData(undefined)}>
              Schließen
            </Button>
            <Button className='btn-drawer btn-drawer-accept' onClick={() => changeStatus('Akzeptiert')}>
              Genehmigen
            </Button>
            <Button className='btn-drawer btn-drawer-decline' onClick={() => setRejectedModal(true)}>
              Ablehnen
            </Button>
          </div>
        </Card>
      </Drawer>
      <Modal
        className='gallery-modal'
        centered
        visible={rejectedModal}
        footer={null}
        onCancel={() => setRejectedModal(false)}
        title={<strong>Ablehnungsgrund</strong>}
        destroyOnClose
      >
        <Card bordered={false} style={{ width: '100%' }}>
          <Input.TextArea
            key={modalData?._id}
            defaultValue={rejectedReason}
            rows={3}
            onChange={(e) => setRejectedReason(e.target.value)}
          />
          <div style={{ marginTop: '1.8rem', float: 'right' }}>
            <Button onClick={() => setRejectedModal(false)}>Schließen</Button>
            <Button
              onClick={() => {
                changeStatus('Abgelehnt');
                setRejectedModal(false);
                setRejectedReason('');
              }}
            >
              Speichern
            </Button>
          </div>
        </Card>
      </Modal>
      <Modal open={openLog} onCancel={() => showLogs(false)} centered footer={null} width={550}>
        <Card title='Statusänderungsprotokoll' bordered={false}>
          <div style={{ margin: '1rem' }}>
            {logs?.map((log, i) => (
              <div
                key={log._id}
                style={{ borderBottom: i !== logs.length - 1 ? '1px solid #ccc' : '', padding: '.4rem 0' }}
              >
                <span>
                  AntragsID <b>{log.antragID}</b>:{' '}
                  <b>
                    {log.user?.firstName} {log.user?.lastName}
                  </b>{' '}
                  hat den Status auf <b style={{ textTransform: 'uppercase' }}>{log.statusSet}</b> geändert.
                </span>
              </div>
            ))}
          </div>
        </Card>
      </Modal>
    </div>
  );
};

export default Studies;
