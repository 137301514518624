import React, { useState, useEffect, useContext } from 'react';
import { Form, Input, Button, Select, Result, notification, Modal, Checkbox } from 'antd';
import { GalleryModal } from './';
import Axios from 'axios';
import { UserContext } from '../../App';
import { useHistory } from 'react-router-dom';

const tailLayout = {
  // wrapperCol: { offset: 8, span: 24 },
  wrapperCol: { span: 24 },
};

const layout = {
  labelCol: { span: 0 },
  wrapperCol: { span: 24 },
};

const initialValues = {
  email: undefined,
  firstName: undefined,
  lastName: undefined,
  password: undefined,
  phone: '',
  legalType: 'PRIVATE',
  address: undefined,
  city: undefined,
  zip: undefined,
  country: undefined,
  avatar: undefined,
  newsletter: undefined,
  role: [],
};

const UserForm = ({
  data,
  updateUserHandler,
  createHandler,
  result,
  setResult,
  SERVER_URL,
  token,
  showModal,
  setShowModal,
  profile,
  semesters,
  isAddUserActive,
}) => {
  let user = data ? { ...data } : initialValues;
  const isNew = data ? false : true;
  const history = useHistory();
  const currentuser = useContext(UserContext);

  const [form] = Form.useForm();
  const [modal, setModal] = useState({ visible: false, formKey: null, limit: 1 });
  const [image, setImage] = useState();
  const [checked, setChecked] = useState(false);
  const [promptName] = useState(); //izbrisano setPromptName
  const [deletePop, setDeletePop] = useState();

  useEffect(() => {
    if (data && data.avatar) setImage(data.avatar.url);
  }, [data]);

  const onFinish = (values) => {
    if (isNew) {
      createHandler(values, checked);
    } else {
      updateUserHandler(values);
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const onInsert = (values, formKey) => {
    form.setFieldsValue({ [formKey]: values });
    setImage(values);
  };

  const deleteUserHandler = async (id) => {
    try {
      await Axios.delete(`${SERVER_URL}/users/${id}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });
      notification.success({
        message: 'Item is deleted.',
        placement: 'bottomRight',
      });
      setTimeout(() => {
        if (id === currentuser.data.id) {
          sessionStorage.removeItem('user');
          currentuser.setLoggedIn(false);
          history.push('/login');
        }
      }, 1200);
    } catch (err) {
      notification.error({
        message: 'Problem with delete. Please try later.',
        placement: 'bottomRight',
      });
    }
  };

  return (
    <div className='dashboard user-form'>
      <div className='panel panel-body'>
        {!result && (
          <Form
            {...layout}
            name='basic'
            initialValues={user}
            onFinish={(values) => onFinish(values, isNew)}
            onFinishFailed={onFinishFailed}
            layout='horizontal'
            form={form}
          >
            <Form.Item
              label='Vorname'
              name='firstName'
              rules={[
                {
                  required: true,
                  message: 'Pflichtfeld!',
                },
              ]}
            >
              <Input placeholder='Vorname' />
            </Form.Item>
            <Form.Item
              label='Nachname'
              name='lastName'
              rules={[
                {
                  required: true,
                  message: 'Pflichtfeld!',
                },
              ]}
            >
              <Input placeholder='Nachname' />
            </Form.Item>
            <Form.Item
              label='Matnr. (K...) AK-Nr. (AK...)'
              name='code'
              rules={[
                {
                  required: true,
                  message: 'Pflichtfeld!',
                },
              ]}
            >
              <Input placeholder='Matnr. (K...) AK-Nr. (AK...)' />
            </Form.Item>

            <Form.Item
              label='E-Mail-Addresse'
              name='email'
              rules={[
                {
                  required: true,
                  message: 'Pflichtfeld!',
                },
              ]}
            >
              <Input placeholder='E-Mail-Addresse' />
            </Form.Item>

            <Form.Item
              label='Passwort'
              name='password'
              rules={[
                {
                  required: true,
                  message: 'Pflichtfeld!',
                },
              ]}
            >
              <Input.Password placeholder='Passwort' />
            </Form.Item>

            <Form.Item
              /*label='Role'*/
              name='role'
              rules={[
                {
                  required: true,
                  message: 'Pflichtfeld!',
                },
              ]}
            >
              <Select getPopupContainer={(trigger) => trigger.parentNode} placeholder='Rolle'>
                <Select.Option value='admin'>admin</Select.Option>
                <Select.Option value='student'>student</Select.Option>
              </Select>
            </Form.Item>

            {/* <Form.Item
                  label='Passwort bestätigen'
                  name='confirmPassword'
                  rules={[
                    {
                      required: true,
                      message: 'Pflichtfeld!',
                    },
                  ]}
                >
                  <Input.Password placeholder='Passwort bestätigen' />
                </Form.Item> */}

            {/* {isNew && (
                  <Form.Item
                    label='Mail-Benachrichtigungen senden'
                    name='newsletter'
                    rules={[
                      {
                        required: true,
                        message: 'Pflichtfeld!',
                      },
                    ]}
                  >
                    <Input placeholder='Mail-Benachrichtigungen senden' />
                  </Form.Item>
                )} */}

            <Form.Item
              label='Semester'
              name='semester'
              rules={[
                {
                  required: true,
                  message: 'Pflichtfeld!',
                },
              ]}
            >
              <Select getPopupContainer={(trigger) => trigger.parentNode} placeholder='Semester'>
                {semesters.data.map((semester) => (
                  <Select.Option value={semester._id}>{semester.name}</Select.Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item label='Mail-Benachrichtigungen senden' name='newsletter'>
              <Checkbox onClick={() => setChecked((prevState) => !prevState)} defaultValue={checked} />
            </Form.Item>

            {!isNew && (
              <Form.Item
                label='Status'
                name='status'
                rules={[
                  {
                    required: true,
                    message: 'Pflichtfeld!',
                  },
                ]}
              >
                <Select getPopupContainer={(trigger) => trigger.parentNode}>
                  <Select.Option value='Aktiv'>Aktiv</Select.Option>
                  <Select.Option value='Deaktiviert'>Deaktiviert</Select.Option>
                </Select>
              </Form.Item>
            )}

            <Form.Item {...tailLayout}>
              <div className='ant-btn-placeholder'>
                <Button type='primary' htmlType='submit' style={{ marginBottom: '20px', width: '100%' }}>
                  {isAddUserActive ? 'Hinzufügen' : 'Daten aktualisieren'}
                </Button>
                <Button type='secondary' onClick={() => setShowModal(!showModal)}>
                  Schließen
                </Button>
              </div>
            </Form.Item>
          </Form>
        )}
        {result && (
          <Result
            title='User has been created and verification mail has been sent'
            extra={
              <Button
                type='primary'
                key='console'
                onClick={() => {
                  setResult(false);
                  form.resetFields();
                }}
              >
                Ok
              </Button>
            }
          />
        )}
        {/* </Card> */}

        {modal.visible && (
          <GalleryModal
            className='gallery-modal'
            visible={modal.visible}
            limit={modal.limit}
            formKey={modal.formKey}
            imageSavePath='public/images/users/'
            imageType='data'
            imageHeight={200}
            SERVER_URL={SERVER_URL}
            token={token}
            form={form}
            onCancel={() => setModal({ ...modal, visible: false })}
            onInsert={(values) => onInsert(values, modal.formKey)}
          />
        )}
        <Modal
          className='deleteModal'
          centered={true}
          visible={deletePop}
          onCancel={() => setDeletePop(!deletePop)}
          onOk={() => deleteUserHandler(user._id)}
          okText='OK'
          cancelText='Schließen'
        >
          <p>Löschen?</p>
          {promptName && <p>{promptName.toUpperCase()}?</p>}
        </Modal>
      </div>
    </div>
  );
};

export default UserForm;
