import React, { useContext } from 'react';
import { Form, Input, Button, Card } from 'antd';
import { UserContext } from '../App';
import { Link } from 'react-router-dom';

const { Meta } = Card;

const backgroundStyle = {
  width: '100vw',
  height: '100vh',
  backgroundImage: `url(/login-bcg.jpg)`,
  backgroundPosition: 'center',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat'
};

const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 24 },
};
const tailLayout = {
  wrapperCol: { span: 24 },
};

const loginCardStyle = {
  borderRadius: '20px',
  background: 'rgba(255, 255, 255, 0.8)'
};

const companyLogoStyle = {
  maxWidth: '400px',
  marginBottom: '50px'
};

const Login = () => {
  const user = useContext(UserContext);

  const onFinish = (values) => {
    user.handleLogin(values);
  };

  return (
    <div className="log" style={backgroundStyle}>
      <div className="card-wrapper">
        <Card className="login-header" bordered={false} style={loginCardStyle}>
          <img style={companyLogoStyle} className="login-logo" src="/images/vpsys-logo.svg" alt="Johannes Kepler Universität Linz Logo" />
          <Meta title="Login" />
          <Form {...layout} name='basic' onFinish={onFinish}>
            <Form.Item name='email' rules={[{ required: true, message: 'Please enter your email od id!' }]}>
              <Input placeholder='E-Mail / Matr.-Nr. / AK-Nr.' />
            </Form.Item>

            <Form.Item name='password' rules={[{ required: true, message: 'Please enter your password!' }]}>
              <Input.Password placeholder='Passwort' />
            </Form.Item>

            <Form.Item {...tailLayout} style={{ marginBottom: 0 }}>
              <Button type='primary' style={{ 'width': '100%' }} htmlType='submit'>Login</Button>
            </Form.Item>
            <Form.Item style={{ paddingTop: '10px', margin: 0 }}>
              <Link to="/register" className="link-forgot-password">Du hast kein Konto? - Registrieren</Link>
            </Form.Item>
            <Form.Item style={{ margin: 0 }}>
              <Link to="/forgot-password" className="link-forgot-password">Passwort vergessen?</Link>
            </Form.Item>
          </Form>
        </Card>
      </div>
    </div>
  );
};

export default Login;
