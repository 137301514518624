import React from 'react';
import { Form, Input, Button, notification } from 'antd';

const formInit = {
  name: undefined,
};

const SemesterForm = ({ data, createHandler, updateHandler }) => {
  const [form] = Form.useForm();
  let initialValues = { ...formInit, ...data, };

  const onFinish = async (values, isNew) => {
    try {
      if (isNew) {
        createHandler(values);
      } else {
        updateHandler(values);
      }
    } catch (error) {
      notification.error({
        message: error.message,
        placement: 'bottomRight'
      });
    }
  };


  return (
    <Form
      className='form-horizontal'
      initialValues={initialValues}
      onFinish={(values) => onFinish(values, !data)}
      layout='vertical'
      form={form}
    >
      <Form.Item name='name'
        rules={[
          {
            required: true,
            message: 'Pflichtfeld!',
          },
        ]}
      >
        <Input />
      </Form.Item>
      <div className='text-right'>
        <Button type='primary' htmlType='submit'>
          Speichern
        </Button>
      </div>
    </Form>
  );
};

export default SemesterForm;