import React, { useContext, useState } from 'react';
import { Table, Input, Button, Popconfirm, Select } from 'antd';
import { DeleteOutlined, SearchOutlined, DeleteFilled, EditOutlined } from '@ant-design/icons';

import { UserContext } from '../../App';
import { EditUser } from '../modals/EditUser';

const { Option } = Select;

const UsersTable = ({ getUsers, data, deleteHandler, columnKeys, title, changeStatus, changeRole }) => {
  const [openModal, setOpenModal] = useState(false);
  const [userData, setUserData] = useState();
  const user = useContext(UserContext);

  let searchInput;

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
      return (
        <div>
          <Input
            ref={(node) => {
              searchInput = node;
            }}
            placeholder={`Suchen ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
          />
          <Button
            type='primary'
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            size='small'
            style={{ width: 90, marginRight: 8, paddingLeft: '25px' }}
          >
            Suchen
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size='small'
            style={{ width: 90, paddingLeft: '27px' }}
            type='secondary'
          >
            Reset
          </Button>
        </div>
      );
    },
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? 'red' : '#bbb', fontSize: '1rem' }} />,
    onFilter: (value, record) => {
      const filterRecord = Array.isArray(dataIndex) ? record[dataIndex[0]][dataIndex[1]] : record[dataIndex];
      if (!filterRecord || !value) return false;
      return filterRecord.toString().toLowerCase().includes(value.toLowerCase());
    },
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => {
          searchInput.select();
        });
      }
    },
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
  };

  const handleReset = (clearFilters) => {
    clearFilters();
  };

  const columns = columnKeys.map((item) => {
    const data = {
      key: item,
      title: item,
      dataIndex: ['title', 'category'].includes(item) ? [item, user.language ? user.language.selected.code : ''] : item,
      ...getColumnSearchProps(
        ['title', 'category'].includes(item) ? [item, user.language ? user.language.selected.code : ''] : item,
      ),
    };

    if (item === 'Name') {
      return {
        ...data,
        sorter: (a, b) => a.Name.localeCompare(b.Name),
        sortDirections: ['descend', 'ascend', 'descend'],
      };
    } else if (item === 'email') {
      return {
        ...data,
        sorter: (a, b) => a.email.localeCompare(b.email),
        sortDirections: ['descend', 'ascend', 'descend'],
      };
    } else if (item === 'VP(Min.)') {
      return {
        ...data,
        sorter: (a, b) => a['VP(Min.)'] - b['VP(Min.)'],
        sortDirections: ['descend', 'ascend', 'descend'],
      };
    } else if (item === 'Matr.Nr./VK-Nr.') {
      return {
        ...data,
        sorter: (a, b) => +a.code?.replace('AK', '').replace('K', '') - b.code?.replace('AK', '').replace('K', ''),
        sortDirections: ['descend', 'ascend', 'descend'],
      };
    } else {
      return data;
    }
  });

  columns.push({
    title: 'Status',
    filters: [
      {
        text: 'Aktiv',
        value: 'Aktiv',
      },
      {
        text: 'Deaktiviert',
        value: 'Deaktiviert',
      },
      {
        text: 'Eingereicht',
        value: 'Eingereicht',
      },
      {
        text: 'Geprüft',
        value: 'Geprüft',
      },
      {
        text: 'Abgeschlossen',
        value: 'Abgeschlossen',
      },
    ],
    onFilter: (value, record) => record.status.indexOf(value) === 0,
    render: (user) => (
      <div>
        <Select
          value={user.status}
          bordered={false}
          style={{ fontSize: '13px', width: '100%' }}
          onChange={(status) => changeStatus(user._id, status)}
        >
          <Option value='Aktiv'>Aktiv</Option>
          <Option value='Deaktiviert'>Deaktiviert</Option>
          <Option value='Eingereicht'>Eingereicht</Option>
          <Option value='Geprüft'>Geprüft</Option>
          <Option value='Abgeschlossen'>Abgeschlossen</Option>
        </Select>
      </div>
    ),
  });

  columns.push({
    title: 'Email Status',
    filters: [
      {
        text: 'Nicht verifiziert',
        value: 'Nicht verifiziert',
      },
      {
        text: 'Verifiziert',
        value: 'Verifiziert',
      },
    ],
    onFilter: (value, item) => item.emailVerified === value,
    render: (item) => item.emailVerified,
  });

  const uniqueSemesters = [...new Set(data.map((x) => x.semester?.name))].sort((a, b) => a.localeCompare(b));
  const filterSemester = uniqueSemesters.filter((s) => s);

  const roles = [];
  data.forEach((r) => r.role.map((x) => roles.push(x)));
  const uniqueRoles = [...new Set(roles)];
  const filterRoles = uniqueRoles.filter((s) => s);

  columns.push({
    title: 'Semester',
    filters: filterSemester.map((s) => ({ text: s, value: s })),
    onFilter: (value, item) => item.semester?.name === value,
    render: (item) => item.semester?.name,
  });

  columns.push({
    title: 'Role',
    filters: filterRoles.map((r) => ({ text: r, value: r })),
    onFilter: (value, item) => item.role.includes(value),
    render: (user) => (
      <div>
        <Select
          value={user.role}
          bordered={false}
          style={{ fontSize: '13px', width: '100%' }}
          onChange={(role) => changeRole(user._id, role)}
        >
          <Option value='admin'>admin</Option>
          <Option value='student'>student</Option>
          {/* <Option value='mitarbeiter'>mitarbeiter</Option> */}
        </Select>
      </div>
    ),
  });

  columns.push({
    title: 'Aktion',
    render: (text, record) => (
      <div style={{ display: 'inline-flex', justifyContent: 'center', alignItems: 'center', fontSize: '15px' }}>
        <div
          style={{ margin: '0 4px', cursor: 'pointer' }}
          className='lock'
          onClick={() => {
            setUserData(record);
            setOpenModal(true);
          }}
        >
          <EditOutlined title='Edit user' style={{ color: '#b3b3b3' }} />
        </div>
        <div style={{ margin: '2px', padding: '4px', cursor: 'pointer' }} className='lock'>
          <Popconfirm
            disabled={record._id === user.data.id}
            placement='left'
            title={`Löschen ${title.toLowerCase()}?`}
            onConfirm={() => {
              deleteHandler(record._id);
            }}
            okText='OK'
            cancelText='Schließen'
            cancelButtonProps={{ type: 'secondary' }}
            okButtonProps={{ type: 'primary' }}
          >
            <DeleteOutlined className='icon-unlock' title={`Delete ${title.toLowerCase()}`} />
            <DeleteFilled className='icon-lock' title={`Delete ${title.toLowerCase()}`} />
          </Popconfirm>
        </div>
      </div>
    ),
  });

  return (
    <div className='table-user-okvir'>
      {/* style={{ height: '94vh' }} */}
      <Table
        size='middle'
        bordered
        dataSource={data}
        columns={columns}
        rowKey='_id'
        pagination={{
          defaultPageSize: 20,
          position: ['topCenter'],
          showSizeChanger: false,
          pageSizeOptions: ['10', '20', '50', '100'],
          hideOnSinglePage: true,
        }}
        scroll={{ y: '88vh' }} // 74vh
      />
      <EditUser
        getUsers={getUsers}
        openModal={openModal}
        setOpenModal={setOpenModal}
        userData={userData}
        setUserData={setUserData}
      />
    </div>
  );
};

export default UsersTable;
