import React, { useContext } from 'react';
import { Table, Input, Button, Popconfirm } from 'antd';
import { DeleteOutlined, EditOutlined, SearchOutlined, StopOutlined } from '@ant-design/icons';
import { UserContext } from '../../App';

const OnlineStudyTable = ({ data, columnKeys, deactivate, openStudy, deleteStudy }) => {
  const user = useContext(UserContext);
  let searchInput;

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
      return (
        <div>
          <Input
            ref={(node) => {
              searchInput = node;
            }}
            placeholder={`Suchen ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
          />
          <Button
            type='primary'
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            size='small'
            style={{ width: 90, marginRight: 8, paddingLeft: '25px' }}
          >
            Suchen
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size='small'
            style={{ width: 90, paddingLeft: '27px' }}
            type='secondary'
          >
            Reset
          </Button>
        </div>
      );
    },
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? 'red' : '#bbb', fontSize: '1rem' }} />,
    onFilter: (value, record) => {
      const filterRecord = Array.isArray(dataIndex) ? record[dataIndex[0]][dataIndex[1]] : record[dataIndex];
      if (dataIndex === 'Bezeichnung') return record.description.toLowerCase().includes(value.toLowerCase());
      if (!filterRecord || !value) return false;
      return filterRecord.toString().toLowerCase().includes(value.toLowerCase());
    },
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => {
          searchInput.select();
        });
      }
    },
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
  };

  const handleReset = (clearFilters) => {
    clearFilters();
  };

  const columns = columnKeys.map((item) => {
    const data = {
      key: item,
      title: item,
      dataIndex: ['title', 'category'].includes(item) ? [item, user.language ? user.language.selected.code : ''] : item,
      ...getColumnSearchProps(
        ['title', 'category'].includes(item) ? [item, user.language ? user.language.selected.code : ''] : item,
      ),
    };

    if (item === 'Bezeichnung') {
      return {
        ...data,
        sorter: (a, b) => a.description.localeCompare(b.description),
      };
    } else if (item === 'Dauaer (Min.)') {
      return {
        ...data,
        sorter: (a, b) => a['Dauaer (Min.)'] - b['Dauaer (Min.)'],
      };
    } else {
      return data;
    }
  });

  columns.push({
    title: 'Status',
    filters: [
      {
        text: 'Online',
        value: 'Online',
      },
      {
        text: 'Abgelaufen',
        value: 'Abgelaufen',
      },
    ],
    onFilter: (value, item) => item.status === value,
    render: (item) => item.status,
  });

  columns.push({
    title: 'Aktion',
    render: (record) => (
      <div style={{ display: 'inline-flex', justifyContent: 'center', alignItems: 'center', fontSize: '15px' }}>
        {record.status !== 'Expired' && (
          <div style={{ margin: '0 4px', cursor: 'pointer' }} className='lock' onClick={() => deactivate(record._id)}>
            <StopOutlined title='Deactivate study' />
          </div>
        )}
        <div style={{ margin: '0 4px', cursor: 'pointer' }} className='lock' onClick={() => openStudy(record)}>
          <EditOutlined title='Edit study' />
        </div>
        {/* <div style={{ margin: '0 4px', cursor: 'pointer' }} className='lock' onClick={() => deleteStudy(record._id)}>
          <DeleteOutlined title='Delete study' />
        </div> */}
        <Popconfirm
          disabled={record._id === user.data.id}
          placement='left'
          title={`Löschen?`}
          onConfirm={() => {
            deleteStudy(record._id);
          }}
          okText='OK'
          cancelText='Schließen'
          cancelButtonProps={{ type: 'secondary' }}
          okButtonProps={{ type: 'primary' }}
        >
          <DeleteOutlined className='icon-lock' title={`Löschen`} />
        </Popconfirm>
      </div>
    ),
  });

  return (
    <div className='table-user-okvir'>
      {' '}
      {/* style={{ height: '94vh' }} */}
      <Table
        size='middle'
        bordered
        dataSource={data}
        columns={columns}
        rowKey='_id'
        pagination={{
          defaultPageSize: 20,
          position: ['topCenter'],
          showSizeChanger: false,
          pageSizeOptions: ['10', '20', '50', '100'],
          hideOnSinglePage: true,
        }}
        scroll={{ y: '88vh' }} // 74vh
      />
    </div>
  );
};

export default OnlineStudyTable;
