import React, { useEffect, useContext, useState } from 'react';
import { Button, Layout, Typography, Card, notification } from 'antd';
import { EditOutlined, LoadingOutlined } from '@ant-design/icons';
import { UserContext } from '../../App';
import useAxios from '../../hooks/useAxios';
import { SERVER_URL } from '../../config';
import dayjs from 'dayjs';
import EditUserModal from '../../components/modals/EditModal';
import axios from 'axios';

const { Content, Sider } = Layout;
const { Text } = Typography;

const ViewUser = (props, editPath) => {
  const { id } = props.match.params;
  const [showModal, setShowModal] = useState(false);
  const currentuser = useContext(UserContext);
  const [user, fetchUser] = useAxios('', null, currentuser.data.token, 'get');
  const [log, fetchLog] = useAxios('', [], currentuser.data.token, 'get');
  const [csv, fetchCSV] = useAxios('', null, currentuser.data.token, 'get');
  const [semesters, fetchSemesters] = useAxios('', null, currentuser.data.token, 'get');

  useEffect(() => {
    if (id) {
      fetchUser(`${SERVER_URL}/users/${id}`, []);
      fetchLog(`${SERVER_URL}/logs/${id}`, []);
    }
  }, [id, fetchUser, fetchLog, user]);

  useEffect(() => {
    fetchSemesters(`${SERVER_URL}/semesters`, []);
  }, [semesters, fetchSemesters]);

  useEffect(() => {
    if (id) {
      let sevenDayBefor = dayjs().subtract(7, 'day').format('YYYY-MM-DD');
      let sevenDayBeforGet = dayjs(sevenDayBefor).format('YYYY-MM-DD');
      let from = sevenDayBeforGet;
      let to = new Date();
      let fromTo = from + '||' + to;
      fetchCSV(`${SERVER_URL}/logsCSV/${id}/${JSON.stringify(fromTo)}`, []);
    }
  }, [id, fetchCSV]);

  const updateUserHandler = async (values) => {
    const userStorage = JSON.parse(sessionStorage.getItem('user'));
    const { token } = userStorage;

    try {
      const { data } = await axios.put(
        `${SERVER_URL}/users/${id}`,
        { ...values },
        { withCredentials: false, headers: { Authorization: `Bearer ${token}` } },
      );
      //Change the useAxios hook dependency so that the page re-renders after the user is updated
      fetchUser('');

      notification.success({
        message: data.message,
        placement: 'bottomRight',
        duration: 2.5,
      });
    } catch (err) {
      if (err.response.data.message) {
        notification.warn({
          message: err.response.data.message,
          duration: 2.5,
          placement: 'bottomRight',
        });
      }
      console.log(err.message);
    } finally {
      setShowModal(false);
      fetchUser(`${SERVER_URL}/users/${id}`, user.data);
    }
  };

  const handleSignOutSubmit = async (event) => {
    event.preventDefault();
    sessionStorage.removeItem('user');
    window.location.href = '/login';
  };

  return (
    <div className='edit-panel'>
      <div className='content content-full-height'>
        {id && user.isLoading && <LoadingOutlined className='loader' spin />}
        {id && !user.isLoading && user.isError && <h2 style={{ marginTop: '5rem' }}>Something went wrong :(</h2>}
        {id && user && !user.isLoading && !user.isError && (
          <Layout className='site-layout-background' style={{ padding: '2rem', background: '#fff' }}>
            <Sider className='site-layout-background' width={300} theme='light'>
              <Button
                type='primary'
                block
                icon={<EditOutlined style={{ marginRight: '5px' }} />}
                style={{ marginTop: '1rem' }}
                onClick={() => setShowModal(!showModal)}
              >
                Benutzer bearbeiten
              </Button>
              {currentuser.data.id === id && (
                <Button style={{ width: '100%', marginTop: '.625rem' }} type='secondary' onClick={handleSignOutSubmit}>
                  Ausloggen
                </Button>
              )}
            </Sider>
            <Content style={{ padding: '0 15px', minHeight: 280, background: '#fff' }}>
              <div className='card-wrapper'>
                <Card title='Nutzerinformation' bordered={false}>
                  <p style={{ padding: '0.625rem 0.625rem 0' }}>
                    <Text strong>Vorname: </Text>
                    {user.data ? user.data.firstName : 'No data'}
                  </p>
                  <p style={{ padding: '0 0.625rem' }}>
                    <Text strong>Nachname: </Text>
                    {user.data ? user.data.lastName : 'No data'}
                  </p>
                  <p style={{ padding: '0 0.625rem' }}>
                    <Text strong>E-mail-Addresse: </Text>
                    {user.data ? user.data.email : 'No data'}
                  </p>
                  <p style={{ padding: '0 0.625rem' }}>
                    <Text strong>Matnr. (K...) AK-Nr. (AK...): </Text>
                    {user.data ? user.data.id : 'No data'}
                  </p>
                  <p style={{ textTransform: 'capitalize', padding: '0 0.625rem' }}>
                    <Text strong>Rolle: </Text>
                    {user.data ? user.data.role[0] : 'No data'}
                  </p>
                  <p style={{ padding: '0 0.625rem' }}>
                    <Text strong>Status: </Text>
                    {user.data ? user.data.status : 'No data'}
                  </p>
                </Card>
              </div>
            </Content>
          </Layout>
        )}
      </div>
      <EditUserModal
        userId={id}
        showModal={showModal}
        setShowModal={setShowModal}
        profile={true}
        updateUserHandler={updateUserHandler}
        semesters={semesters}
        data={user.data || []}
      />
    </div>
  );
};

export default ViewUser;
