import Users from '../pages/users/Users';
import ViewUser from '../pages/users/ViewUser';
import Studies from '../pages/studies';
import OnlineStudies from '../pages/onlineStudy';

const routes = [
  {
    label: 'Benutzer verwalten',
    path: '/admin/users',
    component: Users,
    allowed: ['admin'],
    showInMenu: true,
  },
  {
    label: 'Anträge verwalten',
    path: '/admin/studies',
    component: Studies,
    allowed: ['admin'],
    showInMenu: true,
  },
  {
    label: 'Online studien',
    path: '/admin/online-studies',
    component: OnlineStudies,
    allowed: ['admin'],
    showInMenu: true,
  },
  {
    label: 'View',
    path: '/admin/view-user/:id',
    component: ViewUser,
    allowed: ['admin'],
    showInMenu: false,
  },
];

export default routes;
