import React, { useEffect, useState } from 'react';
import { Button, Drawer, Form, Input, notification, Select } from 'antd';
import axios from 'axios';

import { SERVER_URL } from '../../config';

const { Option } = Select;

const user = JSON.parse(sessionStorage.getItem('user'));
const tailLayout = {
  wrapperCol: { span: 24 },
};

const headers = {
  withCredentials: false,
  headers: {
    Authorization: `Bearer ${user?.token}`,
  },
};

export const EditStudy = ({ getStudies, openModal, setOpenModal, studyData, setStudyData }) => {
  const [form] = Form.useForm();
  const [categories, setCategories] = useState([]);

  const getCategories = async () => {
    try {
      const { data } = await axios.get(`${SERVER_URL}/categories`, headers);
      setCategories(data);
    } catch (err) {
      if (err.response?.data.message) {
        notification.warn({
          message: err.response.data.message,
          placement: 'bottomRight',
          duration: 2.5,
        });
      }
    }
  };

  useEffect(() => {
    getCategories();
  }, []);

  const updateStudy = async (values) => {
    try {
      const { data } = await axios.put(`${SERVER_URL}/studies/${studyData._id}`, { ...values }, headers);
      notification.success({
        message: data.message,
        placement: 'bottomRight',
        duration: 2.5,
      });
      getStudies();
      setOpenModal(false);
    } catch (err) {
      if (err.response.data.message) {
        notification.warn({
          message: err.response.data.message,
          duration: 2.5,
          placement: 'bottomRight',
        });
      }
      console.log(err.message);
    } finally {
      form.resetFields();
      setStudyData(undefined);
    }
  };

  useEffect(() => {
    if (studyData && studyData.subjectAreas) {
      form.setFieldsValue({
        designation: studyData.designation ? studyData.designation : '',
        institution: studyData.institution ? studyData.institution : '',
        description: studyData.description ? studyData.description : '',
        subjectAreas: studyData.subjectAreas._id ? studyData.subjectAreas._id : '',
        questionOneAnswer: studyData.questionOneAnswer ? studyData.questionOneAnswer : '',
        questionTwoAnswer: studyData.questionTwoAnswer ? studyData.questionTwoAnswer : '',
        questionThreeAnswer: studyData.questionThreeAnswer ? studyData.questionThreeAnswer : '',
        questionFourAnswer: studyData.questionFourAnswer ? studyData.questionFourAnswer : '',
      });
    }
  }, [form, studyData]);

  const closeModal = () => {
    form.resetFields();
    setOpenModal(false);
    setStudyData(undefined);
  };

  return (
    <Drawer
      title={`Edit #${studyData && studyData['Antrags ID']}`}
      placement='right'
      onClose={closeModal}
      open={openModal}
      width={420}
    >
      <Form initialValues={studyData} form={form} onFinish={updateStudy}>
        <Form.Item
          label='Bezeichnung'
          name='designation'
          rules={[
            {
              required: true,
              message: 'Pflichtfeld!',
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label='Institution'
          name='institution'
          rules={[
            {
              required: true,
              message: 'Pflichtfeld!',
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label='Beschreibung'
          name='description'
          rules={[
            {
              required: true,
              message: 'Pflichtfeld!',
            },
          ]}
        >
          <Input.TextArea rows={5} />
        </Form.Item>

        <Form.Item
          label='Themenbereiche'
          name='subjectAreas'
          rules={[
            {
              required: true,
              message: 'Pflichtfeld!',
            },
          ]}
        >
          <Select
            showSearch
            value={studyData && studyData?.subjectAreas?._id}
            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          >
            {categories?.map((cat) => (
              <Option value={cat._id} key={cat._id}>
                {cat.name}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          label='Was war(en) Ihrer Meinung nach die Fragestellung(en) der Studie?'
          name='questionOneAnswer'
          rules={[
            {
              required: true,
              message: 'Pflichtfeld!',
            },
          ]}
        >
          <Input.TextArea rows={2} />
        </Form.Item>

        <Form.Item
          label='Welche methodischen Stärken sind Ihnen aufgefallen?'
          name='questionTwoAnswer'
          rules={[
            {
              required: true,
              message: 'Pflichtfeld!',
            },
          ]}
        >
          <Input.TextArea rows={2} />
        </Form.Item>

        <Form.Item
          label='Welche methodischen Schwächen sind Ihnen aufgefallen?'
          name='questionThreeAnswer'
          rules={[
            {
              required: true,
              message: 'Pflichtfeld!',
            },
          ]}
        >
          <Input.TextArea rows={2} />
        </Form.Item>

        <Form.Item
          label='Welche sonstigen Anmerkungen haben Sie zur Studie?'
          name='questionFourAnswer'
          rules={[
            {
              required: true,
              message: 'Pflichtfeld!',
            },
          ]}
        >
          <Input.TextArea rows={2} />
        </Form.Item>

        <Form.Item {...tailLayout}>
          <div className='ant-btn-placeholder'>
            <Button type='primary' htmlType='submit' style={{ marginBottom: '20px', width: '100%' }}>
              Daten aktualisieren
            </Button>
            <Button type='secondary' onClick={closeModal}>
              Schließen
            </Button>
          </div>
        </Form.Item>
      </Form>
    </Drawer>
  );
};
