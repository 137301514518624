import React, { useState, useContext } from 'react';
import Axios from 'axios';
import { useHistory } from 'react-router-dom';
import { Modal, Popconfirm, notification } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { UserContext } from '../../App';
import useAxios from '../../hooks/useAxios';
import { SERVER_URL } from '../../config';
import SemesterForm from '../../components/forms/SemesterForm';

const EditSemester = (props) => {
  const currentuser = useContext(UserContext);
  const [result, setResult] = useState(false);
  const [semester, fetchSemester] = useAxios('', null, currentuser.data.token, 'get');
  const history = useHistory();

  const createSemester = async (data) => {
    try {
      await Axios.post(`${SERVER_URL}/semesters`, data, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });
      notification.success({
        message: 'Sucessful!',
        placement: 'bottomRight',
      });
      history.push('/admin');
      history.push('/admin/users');
    } catch (err) {
      notification.error({
        message: 'Problem!',
        placement: 'bottomRight',
      });
    }
  };

  const deleteSemester = async (id) => {
    try {
      await Axios.delete(`${SERVER_URL}/semesters/${id}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });
      notification.success({
        message: 'Item is deleted.',
        placement: 'bottomRight',
      });
      setTimeout(() => {
        window.location.reload();
      }, 1200);
    } catch (err) {
      notification.error({
        message: 'Problem with delete. Please try later.',
        placement: 'bottomRight',
      });
    }
  };

  const onClose = () => {
    props.setShowSemesterModal(false);
  };

  return (
    <div>
      <div style={{ textAlign: 'center' }}>
        {!semester.isLoading && semester.isError && <h2 style={{ marginTop: '5rem' }}>Something went wrong :(</h2>}
        {semester && !semester.data && (
          <Modal
            title='Semester'
            onCancel={onClose}
            visible={props.showSemesterModal}
            maskClosable
            centered
            className='semester-modal'
          >
            <SemesterForm
              isNew={true}
              data={null}
              createHandler={createSemester}
              result={result}
              setResult={setResult}
              SERVER_URL={SERVER_URL}
              token={currentuser.data.token}
              semesters={props.semesters}
            />
            <div style={{ borderTop: '1px solid #a9a9a9', marginTop: '1rem', padding: '1rem 0' }}>
              {props.semesters &&
                props.semesters.data &&
                props.semesters.data.length > 0 &&
                props.semesters.data.map((semester) => (
                  <div key={semester._id}>
                    - {semester.name}
                    <Popconfirm
                      disabled={semester._id === currentuser.data.id}
                      placement='left'
                      title={`This will delete ${semester.name}`}
                      onConfirm={() => {
                        deleteSemester(semester._id);
                      }}
                      okText='OK'
                      cancelText='Cancel'
                      cancelButtonProps={{ type: 'secondary' }}
                      okButtonProps={{ type: 'primary' }}
                    >
                      <DeleteOutlined />
                    </Popconfirm>
                  </div>
                ))}
            </div>
          </Modal>
        )}
      </div>
    </div>
  );
};

export default EditSemester;
