import React from 'react';
import { Form, Input, Button, Card, notification } from 'antd';
import Axios from 'axios';
import { SERVER_URL } from '../../config';
import { Link, useParams, useHistory } from 'react-router-dom';

const { Meta } = Card;

const backgroundStyle = {
  width: '100vw',
  height: '100vh',
  backgroundImage: `url(/login-bcg.jpg)`,
  backgroundPosition: 'center',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat'
};

const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 24 },
};
const tailLayout = {
  wrapperCol: { span: 24 },
};

const loginCardStyle = {
  borderRadius: '20px',
  background: 'rgba(255, 255, 255, 0.8)'
};

const companyLogoStyle = {
  maxWidth: '400px',
  marginBottom: '50px'
};

const ResetPassword = () => {
  const history = useHistory();
  let { passwordToken } = useParams();

  const onFinish = async (values) => {
    if (values.password !== values.passwordSecond) {
      notification.warn({
        message: "Passwords need to match",
        placement: 'bottomRight',
        duration: 2.5
      });
      return 0;
    }

    try {
      const { data } = await Axios.post(`${SERVER_URL}/password-reset/${passwordToken}`, { password: values.password });
      notification.success({
        message: data.message,
        placement: 'bottomRight',
        duration: 2.5
      });
      setTimeout(() => history.push('/login'), 1000);
    } catch (err) {
      notification.warn({
        message: err.response.data.message,
        placement: 'bottomRight'
      });
    }
  };

  return (
    <div className="log" style={backgroundStyle}>
      <div className="card-wrapper">
        <Card className="login-header" bordered={false} style={loginCardStyle}>
          <img style={companyLogoStyle} className="login-logo" src="/images/vpsys-logo.svg" alt="Johannes Kepler Universität Linz Logo" />
          <Meta title="Neues Passwort" />
          <Form {...layout} name='basic' onFinish={onFinish}>
            <Form.Item name='password' rules={[{ required: true, message: 'Please enter your password!' }]}>
              <Input type='password' placeholder='Passwort' />
            </Form.Item>
            <Form.Item name='passwordSecond' rules={[{ required: true, message: 'Please re-enter your password!' }]}>
              <Input type='password' placeholder='Passwort bestätigen' />
            </Form.Item>

            <Form.Item {...tailLayout} style={{ margin: 0 }}>
              <Button type='primary' style={{ 'width': '100%' }} htmlType='submit'>Bestätigen</Button>
            </Form.Item>

            <Form.Item {...tailLayout} style={{ paddingTop: '10px', margin: 0 }}>
              <Link to="/login" className="link-forgot-password">Zurück zum Login</Link>
            </Form.Item>
          </Form>
        </Card>
      </div>
    </div>
  );
};

export default ResetPassword;