import React, { useState } from 'react';
import Axios from 'axios';
import { notification, Drawer } from 'antd';
import { SERVER_URL } from '../../config';
import { UserForm } from '../../components/forms';

let user = JSON.parse(sessionStorage.getItem('user'));

const EditUserModal = (props) => {
  const { showModal, setShowModal, getUsers, isAddUserActive, data, updateUserHandler } = props;
  const [result, setResult] = useState(false);

  const createUser = async (data, checked) => {
    try {
      await Axios.post(`${SERVER_URL}/register-dashboard`, { ...data, newsletter: checked });
      notification.success({
        message: 'User created.',
        placement: 'bottomRight',
      });
      setResult(true);
      getUsers();
    } catch (err) {
      console.log(err.message);
      notification.error({
        message:
          err.response.data && err.response.data.message
            ? err.response.data.message
            : 'Problem with creating user. Please try later.',
        placement: 'bottomRight',
      });
    }
  };

  const onClose = () => setShowModal(false);

  return (
    <div style={{ textAlign: 'center', height: '100%' }}>
      <Drawer
        title={`${isAddUserActive ? 'Benutzer hinzufügen' : 'Benutzerdaten ändern'}`}
        placement='right'
        onClose={onClose}
        visible={props.showModal}
        width={420}
      >
        <UserForm
          data={data}
          createHandler={createUser}
          result={result}
          setResult={setResult}
          SERVER_URL={SERVER_URL}
          token={user?.token}
          showModal={showModal}
          setShowModal={setShowModal}
          semesters={props.semesters}
          isAddUserActive={isAddUserActive}
          updateUserHandler={updateUserHandler}
        />
      </Drawer>
    </div>
  );
};

export default EditUserModal;
