import React from 'react';
import { Form, Input, Button, Card, Alert, notification } from 'antd';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { SERVER_URL } from '../config';

const { Meta } = Card;

const backgroundStyle = {
  width: '100vw',
  height: '100vh',
  backgroundImage: `url(/login-bcg.jpg)`,
  backgroundPosition: 'center',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  overflow: 'hidden'
};

const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 24 },
};
const tailLayout = {
  wrapperCol: { span: 24 },
};

const loginCardStyle = {
  borderRadius: '20px',
  background: 'rgba(255, 255, 255, 0.8)'
};

const companyLogoStyle = {
  maxWidth: '400px',
  marginBottom: '50px'
};

const Register = () => {
  const onFinish = async (values) => {
    if (values.password !== values.passwordSecond) {
      notification.warn({
        message: "Passwords need to match",
        placement: 'bottomRight',
        duration: 2.5
      });
      return 0;
    }

    try {
      const { data } = await axios.post(`${SERVER_URL}/register`, values);
      notification.success({
        message: data.message,
        placement: 'bottomRight',
        duration: 2.5
      });
    } catch (error) {
      if (error.response.data.message) {
        notification.error({
          message: error.response.data.message,
          placement: 'bottomRight',
          duration: 2.5
        });
      }
      console.log(error.message);
    }
  };

  return (
    <div className="log" style={backgroundStyle}>
      <div className="card-wrapper">
        <Card className="login-header" bordered={false} style={loginCardStyle}>
          <img style={companyLogoStyle} className="login-logo" src="/images/vpsys-logo.svg" alt="Johannes Kepler Universität Linz Logo" />
          <Meta title="Registrieren" />
          <Form {...layout} name='basic' onFinish={onFinish}>
            <Form.Item name='firstName' rules={[{ required: true, message: 'Please enter your first name!' }]}>
              <Input placeholder='Vorname' />
            </Form.Item>
            <Form.Item name='lastName' rules={[{ required: true, message: 'Please enter your last name!' }]}>
              <Input placeholder='Nachname' />
            </Form.Item>
            <Form.Item name='code' rules={[{ required: true, message: 'Please enter your ID!' }]}>
              <Input placeholder='Matr.-Nr.' />
            </Form.Item>
            <Form.Item name='email' rules={[{ required: true, message: 'Please enter your email!' }]}>
              <Input type='email' placeholder='Email' />
            </Form.Item>
            <Form.Item name='password' rules={[{ required: true, message: 'Please enter your password!' }]}>
              <Input.Password placeholder='Passwort' />
            </Form.Item>
            <Form.Item name='passwordSecond' rules={[{ required: true, message: 'Please re-enter your password!' }]}>
              <Input.Password placeholder='Passwort bestätigen' />
            </Form.Item>

            <Alert
              message={<span>Bei Fragen zur Registrierung wenden Sie sich bitte an <strong>aom-tech@jku.at</strong></span>}
              type="info"
              closable
              showIcon
              style={{ width: '25rem' }}
            />
            <Alert
              message={<span>Nach der Erstellung muss der Account erst vom Institut freigeschalten werden. Dies kann einige Tage in Anspruch nehmen.</span>}
              type="info"
              closable
              showIcon
              style={{ width: '25rem', margin: '0.6rem 0' }}
            />

            <Form.Item {...tailLayout} style={{ marginBottom: 0 }}>
              <Button type='primary' style={{ 'width': '100%' }} htmlType='submit'>Account erstellen</Button>
            </Form.Item>
            <Form.Item style={{ paddingTop: '10px', margin: 0 }}>
              <Link to="/login" className="link-forgot-password">Zurück zum Login</Link>
            </Form.Item>
          </Form>
        </Card>
      </div>
    </div>
  );
};

export default Register;
