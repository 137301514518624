import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Button, notification } from 'antd';

import { SERVER_URL } from '../../config';
import OnlineStudyTable from '../../components/tables/OnlineStudyTable';
import OnlineStudyModal from '../../components/modals/OnlineStudyModal';

let user = JSON.parse(sessionStorage.getItem('user'));
const headers = {
  withCredentials: false,
  headers: { Authorization: `Bearer ${user?.token}` },
};

const OnlineStudies = () => {
  const [studies, setStudies] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [studyData, setStudyData] = useState({ data: undefined, mode: '' });
  const [participants, setParticipants] = useState();

  const getSameDescriptions = async () => {
    try {
      const { data } = await axios.get(`${SERVER_URL}/description`, headers);
      setParticipants(data);
    } catch (error) {
      console.log(error.message);
    }
  };

  const getStudies = async () => {
    try {
      const { data } = await axios.get(`${SERVER_URL}/online-studies`, headers);
      setStudies(data);
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    getStudies();
    getSameDescriptions();
  }, []);

  const openStudy = (study) => {
    setShowModal(true);
    setStudyData({ data: study, mode: 'UPDATE' });
  };

  const closeStudy = () => {
    setShowModal(false);
    setStudyData({ data: undefined, mode: '' });
  };

  const columnKeys = ['Bezeichnung', 'Dauaer (Min.)', 'Start', 'Ende', 'Teilgenommen'];

  const tableData = studies?.map((study) => ({
    ...study,
    Bezeichnung: (
      <span
        onClick={() => {
          setShowModal(true);
          setStudyData({ data: study, mode: 'VIEW' });
        }}
        className='hover-link'
      >
        {study.description}
      </span>
    ),
    'Dauaer (Min.)': study.participationDuration,
    Start: new Date(study.start).toLocaleDateString(),
    Ende: new Date(study.end).toLocaleDateString(),
    Teilgenommen: participants?.filter((p) => p.description === study.description).length,
  }));

  const deactivate = async (id) => {
    try {
      const { data } = await axios.delete(`${SERVER_URL}/online-studies/${id}`, headers);
      notification.success({
        message: data.message,
        placement: 'bottomRight',
        duration: 2.5,
      });
      getStudies();
    } catch (error) {
      if (error.response.data.message) {
        notification.error({
          message: error.response.data.message,
          placement: 'bottomRight',
          duration: 2.5,
        });
      }
      console.log(error.message);
    }
  };

  const deleteStudy = async (id) => {
    try {
      const { data } = await axios.delete(`${SERVER_URL}/delete/online-studies/${id}`, headers);
      notification.success({
        message: data.message,
        placement: 'bottomRight',
        duration: 2.5,
      });
      getStudies();
    } catch (error) {
      if (error.response.data.message) {
        notification.error({
          message: error.response.data.message,
          placement: 'bottomRight',
          duration: 2.5,
        });
      }
      console.log(error.message);
    }
  };

  return (
    <div className='table usersWrapper'>
      <div className='actions-block' style={{ marginBottom: '8px' }}>
        <Button type='primary' onClick={() => setShowModal(true)}>
          Studie hinzufügen
        </Button>
      </div>
      {studies?.length > 0 && (
        <OnlineStudyTable
          data={tableData}
          columnKeys={columnKeys}
          deactivate={deactivate}
          openStudy={openStudy}
          deleteStudy={deleteStudy}
          setShowModal={setShowModal}
        />
      )}
      {studies?.length === 0 && (
        <div className='no-data-box'>
          <h2 style={{ textAlign: 'center' }}>Kein Online-Studium</h2>
        </div>
      )}
      <OnlineStudyModal showModal={showModal} getStudies={getStudies} studyData={studyData} closeStudy={closeStudy} />
    </div>
  );
};

export default OnlineStudies;
