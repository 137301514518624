import React from 'react';
import { Form, Input, Button, Card, notification } from 'antd';
import Axios from 'axios';
import { SERVER_URL } from '../../config';
import { Link } from 'react-router-dom';

const { Meta } = Card;

const backgroundStyle = {
  width: '100vw',
  height: '100vh',
  backgroundImage: `url(/login-bcg.jpg)`,
  backgroundPosition: 'center',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat'
};

const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 24 },
};
const tailLayout = {
  wrapperCol: { span: 24 },
};

const loginCardStyle = {
  borderRadius: '20px',
  background: 'rgba(255, 255, 255, 0.8)'
};

const companyLogoStyle = {
  maxWidth: '400px',
  marginBottom: '50px'
};

const ForgotPassword = () => {

  const onFinish = async values => {
    try {
      const sendPasswordLink = await Axios.post(`${SERVER_URL}/forgot-dashboard-password`, { email: values.email }, { withCredentials: false });
      if (sendPasswordLink.data.status) {
        notification.success({
          message: sendPasswordLink.data.status,
          placement: 'bottomRight'
        });
      }
    } catch (err) {
      notification.warn({
        message: err.response.data.message,
        placement: 'bottomRight'
      });
    }
  };

  return (
    <div className="log" style={backgroundStyle}>
      <div className="card-wrapper">
        <Card className="login-header" bordered={false} style={loginCardStyle}>
          <img style={companyLogoStyle} className="login-logo" src="/images/vpsys-logo.svg" alt="Johannes Kepler Universität Linz Logo" />
          <Meta title="Passwort anfordern" />
          <Form {...layout} name='basic' onFinish={onFinish}>
            <Form.Item name='email' rules={[{ required: true, message: 'Please enter your email or ID!' }]}>
              <Input placeholder='Matr.-Nr. / VK-Nr. oder E-Mail' />
            </Form.Item>

            <Form.Item {...tailLayout} style={{ margin: 0 }}>
              <Button type='primary' style={{ 'width': '100%' }} htmlType='submit'>Passwort anfordern</Button>
            </Form.Item>

            <Form.Item {...tailLayout} style={{ paddingTop: '10px', margin: 0 }}>
              <Link to="/login" className="link-forgot-password">Zurück zum Login</Link>
            </Form.Item>
          </Form>
        </Card>
      </div>
    </div>
  );
};

export default ForgotPassword;