import React, { useState, useEffect } from 'react';
import { Card } from 'antd';
import { useParams } from 'react-router-dom';
import { SERVER_URL } from '../../config';
import useAxios from '../../hooks/useAxios';

const { Meta } = Card;

const backgroundStyle = {
  width: '100vw',
  height: '100vh',
  backgroundImage: `url(/login-bcg.jpg)`,
  backgroundPosition: 'center',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat'
};

const loginCardStyle = {
  borderRadius: '20px',
  background: 'rgba(255, 255, 255, 0.8)'
};

const companyLogoStyle = {
  maxWidth: '400px',
  marginBottom: '50px'
};

const EmailVerification = () => {
  let { emailToken } = useParams();
  const [invalidToken, setInvalidToken] = useState(false);
  const [emailVerification, fetchEmailVerification] = useAxios('', [], '', 'get');

  useEffect(() => {
    fetchEmailVerification(`${SERVER_URL}/email-verification/${emailToken}`, {});
    if (emailVerification.data &&
      emailVerification.data.message === "Succesfully activated account") {
      // setTimeout(() => {
      //   window.location.href = '/login';
      // }, 2000);
    } else if (emailVerification.isError) {
      setInvalidToken(true);
    }
  }, [emailToken, emailVerification, emailVerification.data, fetchEmailVerification]);

  return (
    <div className='email-v'>
      <div className="log" style={backgroundStyle}>
        <div className="card-wrapper">
          <Card className="login-header" bordered={false} style={loginCardStyle}>
            <img style={companyLogoStyle} className="login-logo" src="/images/vpsys-logo.svg" alt="Johannes Kepler Universität Linz Logo" />
            <Meta title="Email Verification" style={{ justifyContent: 'center' }} />
            <div>
              {
                !invalidToken ?
                  (
                    <div>
                      <h1>Account is activated!</h1>
                      <br />
                      {/* <h4 style={{ fontWeight: '700' }}>YOU WILL BE REDIRECTED TO LOGIN PAGE</h4> */}
                    </div>
                  )
                  :
                  (
                    <div>
                      <h1 style={{ color: 'red' }}>INVALID TOKEN</h1>
                    </div>
                  )
              }
            </div>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default EmailVerification;