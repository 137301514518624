import React from 'react';
import { Link } from 'react-router-dom';
import { UserOutlined, SolutionOutlined, ReadOutlined } from '@ant-design/icons';

const Home = () => {
  return (
    <div style={{ textAlign: 'center', overflowX: 'auto' }}>
      <div className='home-links'>
        <Link to='/admin/users'>
          <div className='home-links__item'>
            <UserOutlined />
            Benutzer verwalten
          </div>
        </Link>
        <Link to='/admin/studies'>
          <div className='home-links__item'>
            <SolutionOutlined />
            Anträge verwalten
          </div>
        </Link>
        <Link to='/admin/online-studies'>
          <div className='home-links__item'>
            <ReadOutlined />
            Online studien
          </div>
        </Link>
      </div>
    </div>
  );
};

export default Home;
