import React, { useContext, useState } from 'react';
import { Table, Input, Button, Popconfirm } from 'antd';
import { SearchOutlined, EditOutlined, EditFilled } from '@ant-design/icons';
import { UserContext } from '../../App';
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  InfoCircleOutlined,
  DeleteOutlined,
  DeleteFilled,
} from '@ant-design/icons';
import { EditStudy } from '../modals/EditStudy';

let searchInput;

const StudyTable = ({ getStudies, data, columnKeys, setModalData, setComment, deleteStudyHandler }) => {
  const user = useContext(UserContext);
  const [studyData, setStudyData] = useState();
  const [openModal, setOpenModal] = useState(false);

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
      return (
        <div>
          <Input
            ref={(node) => {
              searchInput = node;
            }}
            placeholder={`Suchen ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
          />
          <Button
            type='primary'
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            size='small'
            style={{ width: 90, marginRight: 8, paddingLeft: '25px' }}
          >
            Suchen
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size='small'
            style={{ width: 90, paddingLeft: '27px' }}
            type='secondary'
          >
            Reset
          </Button>
        </div>
      );
    },
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? 'red' : '#bbb', fontSize: '1rem' }} />,
    onFilter: (value, record) => {
      const filterRecord = Array.isArray(dataIndex) ? record[dataIndex[0]][dataIndex[1]] : record[dataIndex];
      if (!filterRecord || !value) return false;
      return filterRecord.toString().toLowerCase().includes(value.toLowerCase());
    },
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => {
          searchInput.select();
        });
      }
    },
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
  };

  const handleReset = (clearFilters) => {
    clearFilters();
  };

  const columns = columnKeys.map((item) => {
    const data = {
      key: item,
      title: item,
      ...getColumnSearchProps(
        ['title', 'category'].includes(item) ? [item, user.language ? user.language.selected.code : ''] : item,
      ),
    };

    if (item === 'Bezeichnung') {
      return {
        ...data,
        sorter: (a, b) => a.Bezeichnung.localeCompare(b.Bezeichnung),
        render: (record) => (
          <span
            className='link-view-study'
            onClick={() => {
              setModalData(record);
              setComment(record.internalComment);
            }}
          >
            {record.designation}
          </span>
        ),
      };
    } else if (item === 'Antrags ID') {
      return {
        ...data,
        sorter: (a, b) => a.id - b.id,
        defaultSortOrder: 'descend',
        render: (record) => record.id,
      };
    } else if (item === 'VP (Min).') {
      return {
        ...data,
        sorter: (a, b) => a.participationDuration - b.participationDuration,
        render: (record) => record.participationDuration,
      };
    } else if (item === 'Name') {
      return {
        ...data,
        sorter: (a, b) => a.Name.localeCompare(b.Name),
        render: (record) => record.Name,
      };
    } else if (item === 'Matnr') {
      return {
        ...data,
        sorter: (a, b) => a.submittedBy.code.localeCompare(b.submittedBy.code),
        render: (item) => item.submittedBy.code,
      };
    } else {
      return {
        ...data,
        dataIndex: ['title', 'category'].includes(item)
          ? [item, user.language ? user.language.selected.code : '']
          : item,
      };
    }
  });

  columns.push({
    title: 'Status',
    render: (record) =>
      record.status === 'Steht Aus' ? (
        <span className='unaccepted-status'>
          <CloseCircleOutlined /> {record.status}
        </span>
      ) : record.status === 'Akzeptiert' ? (
        <span className='accepted-status'>
          <CheckCircleOutlined /> {record.status}
        </span>
      ) : (
        <span className='neutral-status'>
          <InfoCircleOutlined /> {record.status}
        </span>
      ),
  });

  columns.push({
    title: 'Aktion',
    render: (record) => (
      <div style={{ display: 'inline-flex', justifyContent: 'center', alignItems: 'center', fontSize: '15px' }}>
        <div
          style={{ margin: '2px', padding: '4px', cursor: 'pointer' }}
          className='lock'
          onClick={() => {
            setStudyData(record);
            setOpenModal(true);
          }}
        >
          <EditOutlined className='icon-unlock' title='View study' style={{ textDecoration: 'none', color: 'black' }} />
          <EditFilled className='icon-lock' title='View study' style={{ textDecoration: 'none', color: 'black' }} />
        </div>
        {user.data.role.includes('superAdmin') && (
          <div style={{ margin: '2px', padding: '4px', cursor: 'pointer' }} className='lock'>
            <Popconfirm
              disabled={record._id === user.data.id}
              placement='left'
              title={`Löschen?`}
              onConfirm={() => {
                deleteStudyHandler(record._id);
              }}
              okText='OK'
              cancelText='Schließen'
              cancelButtonProps={{ type: 'secondary' }}
              okButtonProps={{ type: 'primary' }}
            >
              <DeleteOutlined className='icon-unlock' title={`Delete`} />
              <DeleteFilled className='icon-lock' title={`Delete`} />
            </Popconfirm>
          </div>
        )}
      </div>
    ),
  });

  return (
    <div className='table-user-okvir'>
      {' '}
      {/* style={{ height: '94vh' }} */}
      <Table
        size='middle'
        bordered
        dataSource={data}
        columns={columns}
        rowKey='_id'
        pagination={{
          defaultPageSize: 20,
          position: ['topCenter'],
          showSizeChanger: false,
          pageSizeOptions: ['10', '20', '50', '100'],
          hideOnSinglePage: true,
        }}
        scroll={{ y: '88vh' }} // 74vh
      />
      <EditStudy
        studyData={studyData}
        setStudyData={setStudyData}
        openModal={openModal}
        setOpenModal={setOpenModal}
        getStudies={getStudies}
      />
    </div>
  );
};

export default StudyTable;
