import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { Layout, Menu } from 'antd';
import { ROUTES } from '../../config';
import { UserContext } from '../../App';
import { UserOutlined, LogoutOutlined } from '@ant-design/icons';

const { Sider } = Layout;

const NavMenu = (props) => {
  const { isCollapsed, collapsedNav } = props;
  const currentuser = useContext(UserContext);

  const handleSignOutSubmit = async (event) => {
    event.preventDefault();
    sessionStorage.removeItem('user');
    window.location.href = '/login';
  };

  return (
    <Sider
      breakpoint='lg'
      className={collapsedNav ? 'nav-menu-collapsed' : 'nav-display'}
      trigger={null}
      collapsible
      collapsed={isCollapsed}
      style={{ zIndex: 6 }}
    >
      <Link to='/admin' className='logo-desktop'>
        <div className='logo'>
          <img src='/images/vpsys-logo.svg' style={{ width: '100%' }} alt='Johannes Kepler Universität Linz api' />
        </div>
      </Link>
      <Menu theme='dark' mode='inline' defaultSelectedKeys={[window.location.pathname]}>
        {ROUTES.filter((item) => item.showInMenu).map((item) => (
          <Menu.Item key={item.path}>
            <Link to={item.path}>{item.label}</Link>
          </Menu.Item>
        ))}
      </Menu>
      <div className='sider-profile-controls'>
        <Link
          to={`/admin/view-user/${currentuser && currentuser?.data?.id}`}
          className='sider-profile-controls-profile'
        >
          <UserOutlined />
        </Link>

        <LogoutOutlined onClick={handleSignOutSubmit} className='sider-profile-controls-logout' />
      </div>
    </Sider>
  );
};
export default NavMenu;
